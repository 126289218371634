export const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        width: '100vw',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 1,
        margin: 0,
        padding: 0,
    },
    videoBackground: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        zIndex: -2,
    },
    videoOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: -1,
    },
    header: {
        fontSize: '2.5rem',
        marginBottom: '20px',
        color: '#fff',
        textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
        textAlign: 'center',
        '@media (max-width: 768px)': {
            fontSize: '2rem',
        },
        '@media (max-width: 480px)': {
            fontSize: '1.5rem',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.85)',
        padding: '20px 30px',
        borderRadius: '20px',
        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.6)',
        '@media (max-width: 768px)': {
            padding: '15px 20px',
            borderRadius: '15px',
        },
        '@media (max-width: 480px)': {
            padding: '10px 15px',
            borderRadius: '10px',
        },
    },
    checkboxContainer: {
        marginBottom: '10px',
        color: '#fff',
    },
    input: {
        padding: '12px',
        fontSize: '1.1rem',
        width: '320px',
        marginBottom: '15px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 768px)': {
            width: '280px',
        },
        '@media (max-width: 480px)': {
            width: '240px',
            fontSize: '1rem',
        },
    },
    textarea: {
        padding: '12px',
        fontSize: '1.1rem',
        width: '320px',
        height: '160px',
        marginBottom: '15px',
        borderRadius: '8px',
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
        '@media (max-width: 768px)': {
            width: '280px',
            height: '140px',
        },
        '@media (max-width: 480px)': {
            width: '240px',
            height: '120px',
            fontSize: '1rem',
        },
    },
    button: {
        padding: '12px 24px',
        fontSize: '1.1rem',
        cursor: 'pointer',
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: 'none',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'background-color 0.3s',
        '@media (max-width: 768px)': {
            padding: '10px 20px',
        },
        '@media (max-width: 480px)': {
            padding: '8px 16px',
            fontSize: '1rem',
        },
    },
    result: {
        marginTop: '20px',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        width: '80%',
        maxWidth: '800px',
        color: '#000',
        '@media (max-width: 768px)': {
            width: '90%',
            padding: '15px',
        },
        '@media (max-width: 480px)': {
            width: '95%',
            padding: '10px',
        },
    },
    correctHeader: {
        color: 'green',
    },
    incorrectHeader: {
        color: 'red',
    },
    correct: {
        color: 'green',
    },
    incorrect: {
        color: 'red',
    },
    disclaimer: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#ffeb3b',
        padding: '10px',
        borderRadius: '5px 5px 0 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 2,
    },
    closeButton: {
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '1.2rem',
        cursor: 'pointer',
        color: '#000',
    },
    subscribeContainer: {
        marginTop: '20px',
    },
    subscribeButton: {
        padding: '10px 20px',
        fontSize: '1.2rem',
        backgroundColor: '#4CAF50',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    error: {
        color: 'red',
        marginTop: '10px',
    },
};
