import React, { useState, useEffect } from 'react';
import Disclaimer from './components/Disclaimer';
import FactCheckForm from './components/FactCheckForm';
import FactCheckResult from './components/FactCheckResult';
import SubscribeButton from './components/SubscribeButton';
import { styles } from './styles';

function App() {
  const [factCheckResult, setFactCheckResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [requestCount, setRequestCount] = useState(0);
  const maxRequests = 5;

  useEffect(() => {
    if (localStorage.getItem('requestCount')) {
      setRequestCount(parseInt(localStorage.getItem('requestCount')));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('requestCount', requestCount);
  }, [requestCount]);

  return (
    <div style={styles.container}>
      <video autoPlay loop muted style={styles.videoBackground}>
        <source src="https://media.istockphoto.com/id/1248484234/video/scottish-fold-cat-is-viewed-through-a-magnifying-glass.mp4?s=mp4-640x640-is&k=20&c=q-q1D2l8NJOsskqXrLcmhaL5zYjUWYW_SW3Xoh2oAwk=" type="video/mp4" />
      </video>
      <div style={styles.videoOverlay}></div>
      <h1 style={styles.header}>Fact Checker</h1>
      <Disclaimer />
      <FactCheckForm
        setFactCheckResult={setFactCheckResult}
        setLoading={setLoading}
        loading={loading}
        requestCount={requestCount}
        setRequestCount={setRequestCount}
        maxRequests={maxRequests}
      />
      {factCheckResult && <FactCheckResult factCheckResult={factCheckResult} />}
      {/* <SubscribeButton /> */}
    </div>
  );
}

export default App;
