import React from 'react';
import { styles } from '../styles';

function FactCheckResult({ factCheckResult }) {
  return (
    <div style={styles.result}>
      <h2>Fact Check Result</h2>
      <div>
        <h3 style={styles.correctHeader}>Correct:</h3>
        <ul>
          {factCheckResult.Correct && factCheckResult.Correct.map((item, index) => (
            <li key={index} style={styles.correct}>{item}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3 style={styles.incorrectHeader}>Incorrect:</h3>
        <ul>
          {factCheckResult.Incorrect && factCheckResult.Incorrect.map((item, index) => (
            <li key={index} style={styles.incorrect}>{item}</li>
          ))}
        </ul>
      </div>
      <div>
        <h3>Summary:</h3>
        <p>{factCheckResult.Summary}</p>
      </div>
    </div>
  );
}

export default FactCheckResult;
