import React, { useState } from 'react';
import { styles } from '../styles';

function FactCheckForm({ setFactCheckResult, setLoading, loading, requestCount, setRequestCount, maxRequests }) {
  const [inputLink, setInputLink] = useState('');
  const [inputText, setInputText] = useState('');
  const [useTextInput, setUseTextInput] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (requestCount >= maxRequests) {
      alert('You have reached the maximum number of requests. Please try again later or subscribe for unlimited access.');
      return;
    }

    setLoading(true);
    setRequestCount(requestCount + 1);
    setErrorMessage(''); // Clear previous errors

    try {
      const payload = useTextInput ? { text: inputText } : { link: inputLink };
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', // Adding the header here
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      if (response.ok) {
        setFactCheckResult(result.fact_check);
      } else {
        setErrorMessage(result.error || 'This content is not supported.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('This content is not supported.');
    } finally {
      setLoading(false);
    }
};


  return (
    <>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.checkboxContainer}>
          <label>
            <input
              type="checkbox"
              checked={useTextInput}
              onChange={(e) => setUseTextInput(e.target.checked)}
            />
            Use Text Input
          </label>
        </div>

        {useTextInput ? (
          <textarea
            placeholder="Enter text to fact-check"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            style={styles.textarea}
          />
        ) : (
          <input
            type="text"
            placeholder="Enter article or video link"
            value={inputLink}
            onChange={(e) => setInputLink(e.target.value)}
            style={styles.input}
          />
        )}

        <button type="submit" style={styles.button}>
          {loading ? 'Checking...' : 'Fact Check'}
        </button>
      </form>
      {errorMessage && <p style={styles.error}>{errorMessage}</p>}
    </>
  );
}

export default FactCheckForm;
