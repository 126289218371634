import React, { useState } from 'react';
import { styles } from '../styles';

function Disclaimer() {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  return (
    <div style={styles.disclaimer}>
      <p>
        Disclaimer: This service is powered by an AI model and may not always provide accurate results.
        Please verify the information independently. We are not responsible for any decisions made based on this service.
      </p>
      <button onClick={() => setIsVisible(false)} style={styles.closeButton}>X</button>
    </div>
  );
}

export default Disclaimer;
