import React from 'react';
import { styles } from '../styles';

function SubscribeButton() {
  return (
    <div style={styles.subscribeContainer}>
      <button style={styles.subscribeButton} onClick={() => alert('Redirecting to payment page...')}>
        Subscribe for Unlimited Access
      </button>
    </div>
  );
}

export default SubscribeButton;
